import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/navigation/Layout"

const NotFoundPage = () => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)
  return (
    <Layout>
      <section
        className="full-screen bg-img text-center cover-background text-white no-padding"
        data-overlay-dark="7"
        data-background="img/content/comingsoon-bg.jpg"
      >
        <div className="container h-100">
          <div className="display-table w-100 h-100 width-55 sm-width-90 padding-four-all xs-padding-eight-tb">
            <div className="display-table-cell vertical-align-middle">
              <div className="row page-container">
                <div className="col-12">
                  <p className="font-size130 alt-font font-weight-300 title">
                    404
                  </p>
                  <h2 className="text-white font-size40 xs-font-size22 margin-30px-bottom font-weight-400">
                    Opps! Page not found
                  </h2>

                  <p className="font-size15 font-weight-200 width-55 xs-width-95 center-col margin-30px-bottom">
                    Not to worry, if you arrived here from one of our other
                    pages, please click the back button to return to the
                    homepage.
                  </p>

                  <Link
                    to="/"
                    className="butn margin-10px-right margin-10px-bottom"
                  >
                    Back to Home
                  </Link>
                  <Link to="/contact" className="butn margin-10px-bottom">
                    Contact Us
                  </Link>
                </div>
              </div>

              <div className="row margin-50px-top sm-margin-25px-top">
                <div className="col-12">
                  &copy; {new Date().getFullYear()}{" "}
                  {site.siteMetadata.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
